import React from 'react';
import { QueryRenderer } from '@cubejs-client/react';
import { ResultSet } from '@cubejs-client/core';

import { Table } from 'antd';
import Utils from '../Utils';
import CsvDownloader from '../CsvDownloader';

const cubejsApi = Utils.cubejsApi();

function WeeklyRepeaters(props: {
  shopIds: string[];
  shopType: string;
  salesChannels?: Array<string> | null;
  salesMethods?: Array<string> | null;
  period: 'day' | 'week' | 'month' | 'quarter' | 'fyear';
  options: { [key: string]: any };
  title: string;
  excludeZeroYen: boolean;
  includeUnpaid: boolean;
}) {
  const filters: Array<any> = [];

  if (props.shopIds.length > 0) {
    if (props.shopType === 'parentShops') {
      filters.push({
        member: 'Orders.kitchenShopId',
        operator: 'equals',
        values: props.shopIds,
      });
    } else {
      filters.push({
        member: 'Orders.shopId',
        operator: 'equals',
        values: props.shopIds,
      });
    }
  }

  if (props.salesChannels) {
    filters.push({
      member: 'Orders.salesChannel',
      operator: 'equals',
      values: props.salesChannels,
    });
  }

  if (props.salesMethods) {
    const salesMethods: Array<string> = [];
    if (props.salesMethods.includes('app')) {
      salesMethods.push('appOrder');
    }
    if (props.salesMethods.includes('self_checkout')) {
      salesMethods.push('selfCheckoutOrder');
    }
    if (props.salesMethods.includes('qr_bento')) {
      salesMethods.push('qrBento');
    }
    if (salesMethods.length > 0) {
      filters.push({
        member: 'Orders.salesMethod',
        operator: 'equals',
        values: salesMethods,
      });
    }
  }

  const segments: Array<string> = [];

  if (props.options && (props.options.excludeZeroYen || props.excludeZeroYen)) {
    segments.push('Orders.excludeZeroYen');
  }

  if (!props.includeUnpaid) {
    segments.push('Orders.paidOrder');
  }

  const renderCharts = (resultSet) => {
    const columnMap = {
      'WeeklyRepeaters.week': {
        title: '週',
        align: 'right',
      },
      'WeeklyRepeaters.repeater': {
        title: '30日リピーター',
        align: 'right',
      },
      'WeeklyRepeaters.newRepeater': {
        title: '新規リピーター',
        align: 'right',
      },
      'WeeklyRepeaters.reRepeater': {
        title: '復活リピーター',
        align: 'right',
      },
    };

    return (
      <Table
        pagination={{ position: ['topLeft'] }}
        columns={resultSet.tableColumns().map((col) => ({ ...col, ...columnMap[col.key] }))}
        dataSource={resultSet.tablePivot()}
      />
    );
  };

  return (
    <>
      <QueryRenderer
        query={{
          measures: ['WeeklyRepeaters.repeater', 'WeeklyRepeaters.newRepeater', 'WeeklyRepeaters.reRepeater'],
          timeDimensions: [],
          order: {
            'WeeklyRepeaters.week': 'desc',
          },
          dimensions: ['WeeklyRepeaters.week'],
          filters,
          segments,
        }}
        cubeApi={cubejsApi}
        render={({ resultSet }: { resultSet: ResultSet | null }) => {
          if (!resultSet) {
            return 'Loading...';
          }
          return (
            <>
              {renderCharts(resultSet)}
              <CsvDownloader title={props.title} resultSet={resultSet} />
            </>
          );
        }}
      />
    </>
  );
}

export default WeeklyRepeaters;
