/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Route, Switch, NavLink, useLocation } from 'react-router-dom';
import withFirebaseAuth from 'react-auth-firebase';
import { useQueryParam, StringParam, BooleanParam, ArrayParam, withDefault, NumberParam } from 'use-query-params';
import dayjs from './dayjs';
import Login from './Login';
import CurryChart from './CurryChart';
import firebase from './firebase';
import './style.css';
import ChartConfig from './ChartConfig';
import TotalCharts from './TotalCharts';
import TotalCharts2 from './TotalCharts2';
import ChurnRate from './charts/ChurnRate';
import CostPerCurry from './charts/CostPerCurry';
import CostUsdPerCurry from './charts/CostUsdPerCurry';
import Passes from './charts/Passes';
import PassUsers from './charts/PassUsers';
import PassUsersChannel from './charts/PassUsersChannel';

function Navigator(props: { user; signOut; error }) {
  const [shops, setShops] = useState<Array<firebase.firestore.QueryDocumentSnapshot>>();
  const [shopIds, setShopIds] = useQueryParam('shopIds', withDefault(ArrayParam, [] as string[]));
  const [shopType, setShopType] = useQueryParam('shopType', withDefault(StringParam, 'parentShops'));
  const [salesChannels, setSalesChannels] = useQueryParam('salesChannels', withDefault(ArrayParam, [] as string[]));
  const [salesMethods, setSalesMethods] = useQueryParam('salesMethods', withDefault(ArrayParam, [] as string[]));

  const [period, setPeriod] = useQueryParam('period', withDefault(StringParam, 'day'));
  const [stack, setStack] = useQueryParam('stack', withDefault(StringParam, 'none'));
  const [chartType, setChartType] = useQueryParam('chartType', withDefault(StringParam, 'bar'));
  const [ravedCustomers, setRavedCustomers] = useQueryParam('ravedCustomers', withDefault(BooleanParam, false));
  const [limitLastNDaysActiveShops, setRecentNDays] = useQueryParam(
    'limitLastNDaysActiveShops',
    withDefault(NumberParam, null),
  );
  const [excludeZeroYen, setExcludeZeroYen] = useQueryParam('excludeZeroYen', withDefault(BooleanParam, false));
  const [includeUnpaid, setIncludeUnpaid] = useQueryParam('includeUnpaid', withDefault(BooleanParam, false));
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);
  const [shopsData, setShopsData] =
    useState<Array<{ id: string; title: string; subTitle: string; shopIds: Array<string> }>>();

  const location = useLocation();
  useEffect(() => {
    setSidebarOpen(false);
  }, [location]);

  useEffect(() => {
    const query: firebase.firestore.CollectionReference | firebase.firestore.Query = firebase
      .firestore()
      .collection('shops');

    query
      .orderBy('order')
      .get()
      .then((snap) => {
        const records: Array<firebase.firestore.QueryDocumentSnapshot> = [];

        for (const docSnapshot of snap.docs) {
          records.push(docSnapshot);
        }

        setShops(records);
      });

    return () => {};
  }, [shopType]);

  useEffect(() => {
    const query: firebase.firestore.CollectionReference | firebase.firestore.Query = firebase
      .firestore()
      .collection('shops');

    query
      .orderBy('order')
      .get()
      .then(async (snap) => {
        const records: Array<{ id: string; title: string; subTitle: string; shopIds: Array<string> }> = [];

        for (const parentShop of snap.docs.filter((doc) =>
          shopType === 'parentShops' ? !doc.data().kitchen_shop_id : true,
        )) {
          if (shopIds.length > 0 && !shopIds.includes(parentShop.id)) {
            continue;
          }

          const ids: Array<string> = [parentShop.id];
          const childShopNames: string[] = [];
          if (shopType === 'parentShops') {
            const children = snap.docs.filter((doc) => doc.data().kitchen_shop_id === parentShop.id);
            for (const child of children) {
              ids.push(child.id);
              childShopNames.push(child.data().short_name);
            }
          }
          records.push({
            id: parentShop.id,
            title: parentShop.data().short_name,
            subTitle: childShopNames.join(', '),
            shopIds: ids,
          });
        }

        setShopsData(records);
      });

    return () => {};
  }, [shopType, shopIds]);

  const { user } = props;
  const { signOut } = props;

  const buildQuery = () => {
    const query: Array<string> = [];

    for (const shopId of shopIds) {
      query.push(`shopIds=${shopId}`);
    }
    for (const salesChannel of salesChannels) {
      query.push(`salesChannels=${salesChannel}`);
    }
    for (const salesMethod of salesMethods) {
      query.push(`salesMethods=${salesMethod}`);
    }
    if (period) {
      query.push(`period=${period}`);
    }
    if (stack) {
      query.push(`stack=${stack}`);
    }
    if (chartType) {
      query.push(`chartType=${chartType}`);
    }
    if (shopType) {
      query.push(`shopType=${shopType}`);
    }
    if (ravedCustomers) {
      query.push(`ravedCustomers=1`);
    }
    if (limitLastNDaysActiveShops) {
      query.push(`limitLastNDaysActiveShops=${limitLastNDaysActiveShops}`);
    }
    if (excludeZeroYen) {
      query.push(`excludeZeroYen=1`);
    }
    if (includeUnpaid) {
      query.push(`includeUnpaid=1`);
    }
    return query.length > 0 ? `?${query.join('&')}` : '';
  };

  if (!user) {
    return <Login />;
  }
  if (user.providerData[0].providerId === 'google.com' && (!user.email || !user.email.match(/@tokyomixcurry.com$/))) {
    signOut();
  }

  const searchAgain = (e) => {
    e.preventDefault();

    setPeriod(e.target.period.value);
    setStack(e.target.stack.checked ? 'expand' : 'none');
    setChartType(e.target.chartType.checked ? 'line' : 'bar');
    setShopType(e.target.shopType.value);
    setSalesChannels(
      Array.from(e.target['salesChannels[]'])
        .map((value: any) => (value.checked ? value.value : null))
        .filter((x) => x),
    );
    setSalesMethods(
      Array.from(e.target['salesMethods[]'])
        .map((value: any) => (value.checked ? value.value : null))
        .filter((x) => x),
    );
    setRavedCustomers(!!e.target.ravedCustomers.checked);
    setRecentNDays(e.target.limitLastNDaysActiveShops.value);
    setExcludeZeroYen(!!e.target.excludeZeroYen.checked);
    setIncludeUnpaid(!!e.target.includeUnpaid.checked);

    const selectedShopIds = e.target.shopIds?.options
      ? [...e.target.shopIds.options].filter((option) => option.selected).map((option) => option.value)
      : [];
    setShopIds(selectedShopIds);
  };

  const months = [
    ...Array(
      dayjs().tz('Asia/Tokyo').diff(dayjs.tz('2019-06-01 00:00:00', 'YYYY-MM-DD HH:mm:ss', 'Asia/Tokyo'), 'month') + 1,
    ),
  ]
    .map((i, index) => {
      const month = dayjs.tz('2019-06-01 00:00:00', 'YYYY-MM-DD HH:mm:ss', 'Asia/Tokyo').add(index, 'month');
      return [month.format('YYYYMM'), month.format('YYYY/MM')];
    })
    .reverse();

  return (
    <div id="navigator">
      <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        <a className="navbar-brand  mr-0 px-3 ml-5 ml-md-0" href="/">
          TOKYO MIX CURRY
        </a>
        <button
          className="navbar-toggler position-absolute d-md-none collapsed"
          type="button"
          onClick={toggleSidebar}
          aria-controls="sidebarMenu"
          aria-expanded={isSidebarOpen}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <ul className="navbar-nav px-3 ml-auto">
          <li className="nav-item text-nowrap">
            <button type="button" className="btn btn-sm btn-secondary" onClick={props.signOut}>
              Sign out
            </button>
          </li>
        </ul>
      </nav>

      <div className="container-fluid">
        <div className="row">
          <nav
            id="sidebarMenu"
            className={`col-md-3 col-lg-2 d-md-block bg-light sidebar collapse ${isSidebarOpen ? 'show' : ''}`}
          >
            <div className="sidebar-sticky pt-3">
              <form onSubmit={searchAgain}>
                <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                  <span>期間</span>
                </h6>

                <div>
                  <ul>
                    <li>
                      <label>
                        日次
                        <input type="radio" name="period" value="day" defaultChecked={!period || period === 'day'} />
                      </label>
                    </li>
                    <li>
                      <label>
                        週次
                        <input type="radio" name="period" value="week" defaultChecked={period === 'week'} />
                      </label>
                    </li>
                    <li>
                      <label>
                        月次 <input type="radio" name="period" value="month" defaultChecked={period === 'month'} />
                      </label>
                    </li>
                    <li>
                      <label>
                        四半期
                        <input type="radio" name="period" value="quarter" defaultChecked={period === 'quarter'} />
                      </label>
                    </li>
                    <li>
                      <label>
                        年次 <input type="radio" name="period" value="fyear" defaultChecked={period === 'fyear'} />
                      </label>
                    </li>
                  </ul>
                </div>

                <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                  <span>店舗選択</span>
                </h6>

                <div>
                  <ul>
                    <li>
                      <label>
                        親店舗毎
                        <input
                          type="radio"
                          name="shopType"
                          value="parentShops"
                          defaultChecked={!shopType || shopType === 'parentShops'}
                        />
                      </label>
                    </li>
                    <li>
                      <label>
                        店舗毎
                        <input
                          type="radio"
                          name="shopType"
                          value="eachShops"
                          defaultChecked={shopType === 'eachShops'}
                        />
                      </label>
                    </li>
                  </ul>
                </div>

                <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                  <span>販売チャネル選択</span>
                </h6>

                <div>
                  <ul>
                    <li>
                      <label>
                        テイクアウト&nbsp;
                        <input
                          type="checkbox"
                          name="salesChannels[]"
                          value="takeout"
                          defaultChecked={salesChannels.includes('takeout')}
                        />
                      </label>
                    </li>
                    <li>
                      <label>
                        イートイン&nbsp;
                        <input
                          type="checkbox"
                          name="salesChannels[]"
                          value="eatin"
                          defaultChecked={salesChannels.includes('eatin')}
                        />
                      </label>
                    </li>
                    <li>
                      <label>
                        法人デリバリー&nbsp;
                        <input
                          type="checkbox"
                          name="salesChannels[]"
                          value="office_delivery"
                          defaultChecked={salesChannels.includes('office_delivery')}
                        />
                      </label>
                    </li>
                    <li>
                      <label>
                        スタンド&nbsp;
                        <input
                          type="checkbox"
                          name="salesChannels[]"
                          value="stand"
                          defaultChecked={salesChannels.includes('stand')}
                        />
                      </label>
                    </li>
                    <li>
                      <label>
                        社食&nbsp;
                        <input
                          type="checkbox"
                          name="salesChannels[]"
                          value="catering"
                          defaultChecked={salesChannels.includes('catering')}
                        />
                      </label>
                    </li>
                    <li>
                      <label>
                        個別デリバリー&nbsp;
                        <input
                          type="checkbox"
                          name="salesChannels[]"
                          value="on_demand_delivery"
                          defaultChecked={salesChannels.includes('on_demand_delivery')}
                        />
                      </label>
                    </li>
                  </ul>
                </div>

                <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                  <span>アプリ/レジ/弁当</span>
                </h6>

                <div>
                  <ul>
                    <li>
                      <label>
                        アプリ&nbsp;
                        <input
                          type="checkbox"
                          name="salesMethods[]"
                          value="app"
                          defaultChecked={salesMethods.includes('app')}
                        />
                      </label>
                    </li>
                    <li>
                      <label>
                        セルフレジ&nbsp;
                        <input
                          type="checkbox"
                          name="salesMethods[]"
                          value="self_checkout"
                          defaultChecked={salesMethods.includes('self_checkout')}
                        />
                      </label>
                    </li>
                    <li>
                      <label>
                        弁当&nbsp;
                        <input
                          type="checkbox"
                          name="salesMethods[]"
                          value="qr_bento"
                          defaultChecked={salesMethods.includes('qr_bento')}
                        />
                      </label>
                    </li>
                  </ul>
                </div>

                <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                  <span>その他</span>
                </h6>

                <div>
                  <ul>
                    <li>
                      <label className="form-check-label">
                        帯グラフ <input type="checkbox" name="stack" defaultChecked={stack === 'expand'} />
                      </label>
                    </li>
                    <li>
                      <label className="form-check-label">
                        折れ線グラフ <input type="checkbox" name="chartType" defaultChecked={chartType === 'line'} />
                      </label>
                    </li>
                    <li>
                      <label className="form-check-label">
                        5回目以上 <input type="checkbox" name="ravedCustomers" defaultChecked={!!ravedCustomers} />
                      </label>
                    </li>
                    <li>
                      <label className="form-check-label">
                        直近N日以内にアクティブな店舗でフィルター{' '}
                        <input
                          type="text"
                          name="limitLastNDaysActiveShops"
                          defaultValue={limitLastNDaysActiveShops?.toString()}
                          size={5}
                        />
                      </label>
                    </li>
                    <li>
                      <label className="form-check-label">
                        0円を除く <input type="checkbox" name="excludeZeroYen" defaultChecked={!!excludeZeroYen} />
                      </label>
                    </li>
                    <li>
                      <label className="form-check-label">
                        未払いを含む <input type="checkbox" name="includeUnpaid" defaultChecked={!!includeUnpaid} />
                      </label>
                    </li>
                  </ul>
                </div>

                <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                  <span>店舗</span>
                </h6>

                <div>
                  <select multiple className="form-select col-12" name="shopIds" size={15}>
                    {shops &&
                      shops.map((shop) => (
                        <option key={shop.id} value={shop.id} selected={shopIds?.includes(shop.id)}>
                          {shop.data().short_name}
                        </option>
                      ))}
                  </select>
                </div>

                <button className="btn btn-primary btn-sm" type="submit">
                  再検索
                </button>
              </form>

              <hr />

              <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>総合</span>
              </h6>
              <ul className="nav flex-column">
                <li className="nav-item">
                  <NavLink
                    to={{ pathname: '/total_charts', search: buildQuery() }}
                    className="nav-link"
                    activeClassName="active"
                  >
                    総合1(5回目以上対応)
                  </NavLink>
                  <NavLink
                    to={{ pathname: '/total_charts2', search: buildQuery() }}
                    className="nav-link"
                    activeClassName="active"
                  >
                    総合2
                  </NavLink>
                </li>
              </ul>

              <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>レポート別</span>
              </h6>
              <ul className="nav flex-column">
                {[...ChartConfig.all(), ...ChartConfig.all2()].map((chart) => (
                  <li key={chart.path} className="nav-item">
                    <NavLink
                      to={{ pathname: chart.path, search: buildQuery() }}
                      className="nav-link"
                      activeClassName="active"
                    >
                      {chart.title}
                    </NavLink>
                  </li>
                ))}
              </ul>

              <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>その他</span>
              </h6>
              <ul className="nav flex-column mb-2">
                <li className="nav-item">
                  <NavLink to="/other/churn_rate" className="nav-link" activeClassName="active">
                    店舗別離脱率
                  </NavLink>
                </li>
                {months.map((yyyymm) => (
                  <li className="nav-item">
                    <NavLink to={`/other/churn_rate?yyyymm=${yyyymm[0]}`} className="nav-link" activeClassName="active">
                      店舗別離脱率 {yyyymm[1]}
                    </NavLink>
                  </li>
                ))}

                <li className="nav-item">
                  <NavLink to="/other/passes" className="nav-link" activeClassName="active">
                    パス購入数
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/other/pass_users" className="nav-link" activeClassName="active">
                    パスアクティブユーザー数
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/other/pass_users_channel" className="nav-link" activeClassName="active">
                    パスアクティブユーザー数(チャンネル別)
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/other/cost_per_curry" className="nav-link" activeClassName="active">
                    GCPコスト/食
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/other/cost_usd_per_curry" className="nav-link" activeClassName="active">
                    GCPコスト/食(ドル)
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>

          {shopsData ? (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
              <Switch>
                {[...ChartConfig.all(), ...ChartConfig.all2()].map((chart) => (
                  <Route
                    key={chart.path}
                    path={chart.path}
                    render={(p) => (
                      <CurryChart
                        {...p}
                        title={chart.title}
                        component={chart.component}
                        options={chart.options}
                        withShops
                        shops={shopsData}
                      />
                    )}
                  />
                ))}

                <Route path="/other/churn_rate" render={(p) => <ChurnRate {...p} title="店舗別離脱率" />} />
                <Route path="/other/cost_per_curry" render={(p) => <CostPerCurry {...p} title="GCPコスト/食" />} />
                <Route
                  path="/other/cost_usd_per_curry"
                  render={(p) => <CostUsdPerCurry {...p} title="GCPコスト/食" />}
                />
                <Route
                  path="/other/passes"
                  render={(p) => <CurryChart {...p} title="パス購入数" component={Passes} shops={shopsData} />}
                />

                <Route
                  path="/other/pass_users"
                  render={(p) => (
                    <CurryChart {...p} title="パスアクティブユーザー数" component={PassUsers} shops={shopsData} />
                  )}
                />
                <Route
                  path="/other/pass_users_channel"
                  render={(p) => (
                    <CurryChart
                      {...p}
                      title="パスアクティブユーザー数(チャネル別)"
                      component={PassUsersChannel}
                      shops={shopsData}
                    />
                  )}
                />
                <Route path="/total_charts2" render={() => <TotalCharts2 shops={shopsData} />} />
                <Route path="/" render={() => <TotalCharts shops={shopsData} />} />
              </Switch>
            </main>
          ) : (
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </main>
          )}
        </div>
      </div>
    </div>
  );
}

const authConfig = {
  email: {
    verifyOnSignup: false,
    saveUserInDatabase: true,
  },
};

export default withFirebaseAuth(Navigator, firebase, authConfig);
