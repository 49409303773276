import React from 'react';
import { QueryRenderer } from '@cubejs-client/react';
import { ResultSet } from '@cubejs-client/core';
import Utils from '../Utils';
import CsvDownloader from '../CsvDownloader';
import CustomChart from '../CustomChart';

const cubejsApi = Utils.cubejsApi();
const md5 = require('md5');

function Passes(props: {
  shopIds: string[];
  shopType: string;
  salesChannels?: Array<string> | null;
  salesMethods?: Array<string> | null;
  period: 'day' | 'week' | 'month' | 'quarter' | 'fyear';
  chartType: 'bar' | 'line';
  stack: string;
  options: { [key: string]: any };
  title: string;
}) {
  const segments: Array<string> = [];

  const colors = (word) => makeColor(word);

  const makeColor = (word: string) => {
    const seed = parseInt(md5(word), 16);
    const random = Math.floor(Math.abs(Math.cos(seed) * 16777215) % 16777215);
    let color = random.toString(16);
    while (color.length < 6) {
      color = `0${color}`;
    }
    return `#${color}`;
  };

  return (
    <>
      <QueryRenderer
        query={{
          timeDimensions: [
            {
              dimension: 'Passes.orderedAt',
              granularity: props.period as any,
              dateRange: props.period === 'day' ? 'from 1 year ago to now' : '2019-06-01 to now',
            },
          ],
          measures: ['Passes.numberOfOrdered'],
          order: {
            'Passes.orderedAt': 'asc',
          },
          filters: [],
          segments,
          dimensions: ['Passes.passType'],
        }}
        cubeApi={cubejsApi}
        render={({ resultSet }: { resultSet: ResultSet | null }) => {
          if (!resultSet) {
            return 'Loading...';
          }
          const data = resultSet.chartPivot({ ...resultSet.normalizePivotConfig(), fillMissingDates: false });

          const creaedColors = Object.fromEntries(
            resultSet.seriesNames().map((series) => [series.yValues[0], colors(series.yValues[0])]),
          );

          return (
            <>
              <CustomChart
                colors={creaedColors}
                data={data}
                resultSet={resultSet}
                stack={props.stack}
                period={props.period}
                type={props.chartType}
              />
              <CsvDownloader title={props.title} resultSet={data} />
            </>
          );
        }}
      />
    </>
  );
}

export default Passes;
