import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';
import { QueryRenderer } from '@cubejs-client/react';
import { ResultSet } from '@cubejs-client/core';
import dayjs from '../dayjs';
import Utils from '../Utils';
import CsvDownloader from '../CsvDownloader';
import CustomTooltip from '../CustomTooltip';
import CustomChart from '../CustomChart';

const cubejsApi = Utils.cubejsApi();

function PassUsersChannel(props: {
  period: 'day' | 'week' | 'month' | 'quarter' | 'fyear';
  chartType: 'bar' | 'line';
  stack: string;
  title: string;
}) {
  const colors = {
    テイクアウト: '#4285F4',
    イートイン: '#8bb4f8',
    法人デリバリー: '#F4B400',
    その他: '#8BC34A',
  };

  const segments: Array<string> = [];

  return (
    <>
      <QueryRenderer
        query={{
          timeDimensions: [
            {
              dimension: 'PassUsers.activeAt',
              granularity: props.period as any,
              dateRange: props.period === 'day' ? 'from 1 year ago to now' : '2019-06-01 to now',
            },
          ],
          measures: ['PassUsers.numberOfUsers'],
          order: {
            'PassUsers.activeAt': 'asc',
          },
          filters: [],
          segments,
          dimensions: ['PassUsers.channel'],
        }}
        cubeApi={cubejsApi}
        render={({ resultSet }: { resultSet: ResultSet | null }) => {
          if (!resultSet) {
            return 'Loading...';
          }
          const data = resultSet.chartPivot({
            ...resultSet.normalizePivotConfig(),
            fillMissingDates: false,
          });
          return (
            <>
              <CustomChart
                colors={colors}
                data={data}
                resultSet={resultSet}
                stack={props.stack}
                period={props.period}
                type={props.chartType}
              />
              <CsvDownloader title={props.title} resultSet={data} />
            </>
          );
        }}
      />
    </>
  );
}

export default PassUsersChannel;
