import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { useQueryParam, StringParam, BooleanParam, ArrayParam, withDefault, NumberParam } from 'use-query-params';
import 'firebase/compat/auth';

function CurryChart(props: {
  title: string;
  component: React.FunctionComponent<{
    shopIds: string[];
    shopType: string;
    salesChannels?: Array<string> | null;
    salesMethods?: Array<string> | null;
    period: 'day' | 'week' | 'month' | 'quarter' | 'fyear';
    stack: string;
    chartType: 'bar' | 'line';
    options: { [key: string]: any };
    title: string;
    ravedCustomers: boolean;
    limitLastNDaysActiveShops: number | null;
    excludeZeroYen: boolean;
    includeUnpaid: boolean;
  }>;
  options: { [key: string]: any };
  withShops: boolean;
  shops: Array<{ id: string; title: string; subTitle: string; shopIds: Array<string> }>;
}) {
  const [shopIds] = useQueryParam('shopIds', withDefault(ArrayParam, [] as string[]));
  const [salesChannels = null] = useQueryParam('salesChannels', ArrayParam);
  const [salesMethods = null] = useQueryParam('salesMethods', ArrayParam);
  const [period = 'day'] = useQueryParam('period', StringParam);
  const [stack = 'none'] = useQueryParam('stack', StringParam);
  const [chartType = 'bar'] = useQueryParam('chartType', StringParam);
  const [shopType = 'parentShops'] = useQueryParam('shopType', StringParam);
  const [ravedCustomers = false] = useQueryParam('ravedCustomers', BooleanParam);
  const [limitLastNDaysActiveShops = null] = useQueryParam('limitLastNDaysActiveShops', NumberParam);
  const [excludeZeroYen = false] = useQueryParam('excludeZeroYen', BooleanParam);
  const [includeUnpaid = false] = useQueryParam('includeUnpaid', BooleanParam);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">{props.title}</h1>
      </div>

      <h3>合計</h3>

      {props.shops ? (
        <>
          <props.component
            shopIds={shopIds as string[]}
            salesChannels={salesChannels && salesChannels.length > 0 ? (salesChannels as string[]) : null}
            salesMethods={salesMethods && salesMethods.length > 0 ? (salesMethods as string[]) : null}
            shopType={shopType!}
            options={props.options}
            period={period! as 'day' | 'week' | 'month' | 'quarter' | 'fyear'}
            stack={stack!}
            chartType={chartType! as 'bar' | 'line'}
            title={`${props.title}-合計`}
            ravedCustomers={!!ravedCustomers}
            limitLastNDaysActiveShops={limitLastNDaysActiveShops}
            excludeZeroYen={!!excludeZeroYen}
            includeUnpaid={!!includeUnpaid}
          />

          {props.withShops
            ? props.shops.map((shop) => (
                <div key={shop.id}>
                  <h3>{shop.title}</h3>
                  <small>{shop.subTitle}</small>
                  <props.component
                    shopIds={[shop.id]}
                    salesChannels={salesChannels && salesChannels.length > 0 ? (salesChannels as string[]) : null}
                    salesMethods={salesMethods && salesMethods.length > 0 ? (salesMethods as string[]) : null}
                    shopType={shopType!}
                    period={(period || 'day') as 'day' | 'week' | 'month' | 'quarter' | 'fyear'}
                    stack={stack || 'none'}
                    chartType={(chartType || 'bar') as 'bar' | 'line'}
                    options={props.options}
                    title={`${props.title}-${shop.title}`}
                    ravedCustomers={!!ravedCustomers}
                    limitLastNDaysActiveShops={limitLastNDaysActiveShops}
                    excludeZeroYen={!!excludeZeroYen}
                    includeUnpaid={!!includeUnpaid}
                  />
                </div>
              ))
            : null}
        </>
      ) : (
        'loading...'
      )}
    </>
  );
}

export default CurryChart;
