import React from 'react';
import ChartConfig from './ChartConfig';
import CurryChart from './CurryChart';

function TotalCharts(props: { shops: { id: string; title: string; subTitle: string; shopIds: Array<string> }[] }) {
  return (
    <>
      {ChartConfig.all().map((chart) => (
        <React.Fragment key={chart.path}>
          <CurryChart
            title={`${chart.title}`}
            component={chart.component}
            options={chart.options}
            withShops={false}
            shops={props.shops}
          />
        </React.Fragment>
      ))}
    </>
  );
}

export default TotalCharts;
