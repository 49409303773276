import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';
import { QueryRenderer } from '@cubejs-client/react';
import { ResultSet } from '@cubejs-client/core';
import dayjs from '../dayjs';
import Utils from '../Utils';
import CsvDownloader from '../CsvDownloader';

const cubejsApi = Utils.cubejsApi();

function Repeaters(props: {
  shopIds: string[];
  shopType: string;
  salesChannels?: Array<string> | null;
  period: 'day' | 'week' | 'month' | 'quarter' | 'fyear';
  salesMethods?: Array<string> | null;
  title: string;
  excludeZeroYen: boolean;
  includeUnpaid: boolean;
}) {
  const formatXAxis = (tickItem) => {
    if (!tickItem) {
      return 'N/A';
    }
    const a = tickItem.split(',');
    return Utils.formatXAxisLabel(a[0], props.period);
  };

  const filters: Array<any> = [];

  if (props.shopIds.length > 0) {
    if (props.shopType === 'parentShops') {
      filters.push({
        member: 'Orders.kitchenShopId',
        operator: 'equals',
        values: props.shopIds,
      });
    } else {
      filters.push({
        member: 'Orders.shopId',
        operator: 'equals',
        values: props.shopIds,
      });
    }
  }

  if (props.shopIds.length > 0) {
    if (props.shopType === 'parentShops') {
      filters.push({
        member: 'Orders.kitchenShopId',
        operator: 'equals',
        values: props.shopIds,
      });
    } else {
      filters.push({
        member: 'Orders.shopId',
        operator: 'equals',
        values: props.shopIds,
      });
    }
  }

  if (props.salesChannels) {
    filters.push({
      member: 'Orders.salesChannel',
      operator: 'equals',
      values: props.salesChannels,
    });
  }

  if (props.salesMethods) {
    const salesMethods: Array<string> = [];
    if (props.salesMethods.includes('app')) {
      salesMethods.push('appOrder');
    }
    if (props.salesMethods.includes('self_checkout')) {
      salesMethods.push('selfCheckoutOrder');
    }
    if (props.salesMethods.includes('qr_bento')) {
      salesMethods.push('qrBento');
    }
    if (salesMethods.length > 0) {
      filters.push({
        member: 'Orders.salesMethod',
        operator: 'equals',
        values: salesMethods,
      });
    }
  }

  const segments: Array<string> = [];

  if (props.excludeZeroYen) {
    segments.push('Orders.excludeZeroYen');
  }

  if (!props.includeUnpaid) {
    segments.push('Orders.paidOrder');
  }

  const sortOrder = ['7日', '14日', '30日', '60日', '90日', '180日'];
  const sortSeries = (a, b) => {
    // Extract the label before the comma
    const getLabel = (series) => series.title.split(',')[0];

    const labelA = getLabel(a);
    const labelB = getLabel(b);

    let positionA = sortOrder.findIndex((label) => label === labelA);
    let positionB = sortOrder.findIndex((label) => label === labelB);

    if (positionA < 0) {
      positionA = 99999;
    }
    if (positionB < 0) {
      positionB = 99999;
    }

    if (positionA >= positionB) {
      return 1;
    }
    return -1;
  };

  const keyNameMap = {
    'Repeaters.days7Repeaters': '7日',
    'Repeaters.days14Repeaters': '14日',
    'Repeaters.days30Repeaters': '30日',
    'Repeaters.days60Repeaters': '60日',
    'Repeaters.days90Repeaters': '90日',
    'Repeaters.days180Repeaters': '180日',
  };

  const colors = {
    'Repeaters.days7Repeaters': '#ff0000',
    'Repeaters.days14Repeaters': '#ff8000',
    'Repeaters.days30Repeaters': '#ffbf00',
    'Repeaters.days60Repeaters': '#80ff00',
    'Repeaters.days90Repeaters': '#00ffff',
    'Repeaters.days180Repeaters': '#0000ff',
  };

  const renderCharts = (resultSet) =>
    resultSet
      .seriesNames()
      .sort(sortSeries)
      .map((series, i) => {
        const name = series.title.split(',')[0];
        return (
          <Line
            key={series.key}
            dataKey={series.key}
            name={keyNameMap[series.key]}
            stroke={colors[series.key]}
            dot={false}
          />
        );
      });
  return (
    <>
      <QueryRenderer
        query={{
          measures: [
            'Repeaters.days7Repeaters',
            'Repeaters.days14Repeaters',
            'Repeaters.days30Repeaters',
            'Repeaters.days60Repeaters',
            'Repeaters.days90Repeaters',
            'Repeaters.days180Repeaters',
          ],
          timeDimensions: [
            {
              dimension: 'Repeaters.day',
              granularity: 'day',
            },
          ],
          order: {
            'Repeaters.day': 'asc',
          },
          filters,
          segments,
          dimensions: [],
        }}
        cubeApi={cubejsApi}
        render={({ resultSet }: { resultSet: ResultSet | null }) => {
          if (!resultSet) {
            return 'Loading...';
          }

          const data = resultSet.chartPivot({ ...resultSet.normalizePivotConfig(), fillMissingDates: false });
          return (
            <>
              <ResponsiveContainer width="100%" height={600}>
                <LineChart data={data}>
                  <Legend layout="horizontal" verticalAlign="top" align="center" />
                  <XAxis dataKey="x" tickFormatter={formatXAxis} tick={{}} />
                  <YAxis />
                  <CartesianGrid />

                  {renderCharts(resultSet)}

                  <Tooltip itemSorter={(item) => -(item.value || 0)} />
                </LineChart>
              </ResponsiveContainer>
              <CsvDownloader title={props.title} resultSet={data} />
            </>
          );
        }}
      />
    </>
  );
}

export default Repeaters;
