import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  Line,
} from 'recharts';

import { ChartPivotRow, ResultSet } from '@cubejs-client/core';
import Utils from './Utils';
import CustomTooltip from './CustomTooltip';

function CustomChart(props: {
  colors: { [key: string]: string };
  data: ChartPivotRow[];
  resultSet: ResultSet<any>;
  stack: string;
  period: 'day' | 'week' | 'month' | 'quarter' | 'fyear';
  type: 'bar' | 'line';
}) {
  const sortOrder = Object.entries(props.colors).map(([key, value]) => key);

  const sortSeries = (a, b) => {
    // Extract the label before the comma
    const getLabel = (series) => series.title.split(',')[0];

    const labelA = getLabel(a);
    const labelB = getLabel(b);

    let positionA = sortOrder.findIndex((label) => label === labelA);
    let positionB = sortOrder.findIndex((label) => label === labelB);

    if (positionA < 0) {
      positionA = 99999;
    }
    if (positionB < 0) {
      positionB = 99999;
    }

    if (positionA >= positionB) {
      return 1;
    }
    return -1;
  };

  const formatXAxis = (tickItem) => {
    if (!tickItem) {
      return 'N/A';
    }
    const a = tickItem.split(',');
    return Utils.formatXAxisLabel(a[0], props.period);
  };

  const renderCharts = (resultSet) =>
    resultSet
      .seriesNames()
      .sort(sortSeries)
      .map((series, i) => {
        // Extract the first number from the title for the name
        const name = series.title.split(',')[0];
        return props.type === 'line' ? (
          <Line key={series.key} dataKey={series.key} name={name} stroke={props.colors[name]} dot={false} />
        ) : (
          <Bar key={series.key} stackId="a" dataKey={series.key} name={name} fill={props.colors[name]} />
        );
      });

  return (
    <ResponsiveContainer width="100%" height={600}>
      {props.type === 'line' ? (
        <LineChart data={props.data} stackOffset={(props.stack as 'expand') ?? 'none'}>
          <Legend layout="horizontal" verticalAlign="top" align="center" />
          <XAxis dataKey="x" tickFormatter={formatXAxis} />
          <YAxis />
          <CartesianGrid />

          {renderCharts(props.resultSet)}

          <Tooltip content={<CustomTooltip />} />
        </LineChart>
      ) : (
        <BarChart data={props.data} stackOffset={(props.stack as 'expand') ?? 'none'}>
          <Legend layout="horizontal" verticalAlign="top" align="center" />
          <XAxis dataKey="x" tickFormatter={formatXAxis} />
          <YAxis />
          <CartesianGrid />

          {renderCharts(props.resultSet)}

          <Tooltip content={<CustomTooltip />} />
        </BarChart>
      )}
    </ResponsiveContainer>
  );
}

export default CustomChart;
